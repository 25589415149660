/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { QrReader } from "react-qr-reader";
import { Card, CardContent, Typography } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useNotify } from "react-admin";
import Grid from "@mui/material/Grid";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import IconButton from "@mui/material/IconButton";

import boop from "../../assets/beep-07a.mp3";
import InfoCard from "./InfoCard";
import SpeakerCard from "./speakerCard";

const QrCheck = ({
  details,
  onConfirm,
  confirmDelegate,
  printLabel,
  printSpeaker,
  speaker,
  data,
  reload,
  checkingQr,
  setCheckingQr,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const keepFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    document.addEventListener("click", keepFocus);
    document.addEventListener("focusout", keepFocus); // Ensures input stays focused

    return () => {
      document.removeEventListener("click", keepFocus);
      document.removeEventListener("focusout", keepFocus);
    };
  }, []);

  const handleScan = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission if inside a form
      const text = e.target.value;
      const params = (text || "").replace(/^https?:\/\//, "").split("/");
      const id = params?.[1] || "";

      if (id) {
        await confirmDelegate(id);
      } else {
        notify("INVALID QR", { type: "warning" });
      }

      e.target.value = ""; // Clear input for next scan
      inputRef.current.focus();
    }
  };

  const audio = new Audio(boop);
  const notify = useNotify();
  const [facingMode, setFacingMode] = useState("user");

  const onQrCheck = async (result, error) => {
    if (result && !checkingQr) {
      setCheckingQr(true);
      audio.play();
      const params = (result?.text || "").replace(/^https?:\/\//, "").split("/");
      const id = params?.[1] || "";

      if (id) {
        await confirmDelegate(id);
      } else {
        notify("INVALID QR", { type: "warning" });
      }
    }

    if (error) {
      // eslint-disable-next-line no-console
      console.info(error);
    }
  };

  const onClick = (face) => {
    setFacingMode(face);
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: {
            exact: face,
          },
        },
      })
      .then((stream) => {
        const el = document.getElementById("QRVIDEO");
        el.srcObject = stream;
      })
      .catch(console.error);
  };
  const size = !!(
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  );

  return (
    <Grid container spacing={2}>
      {details.useWebcam ? (
        <Grid item lg={6} xs={12}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                minHeight: "33vh",
                marginTop: "1rem",
                padding: "0 1rem",
                width: "100%",
              }}
            >
              {!reload && (
                <Card style={{ height: "100%", width: "100%" }}>
                  <QrReader
                    onResult={onQrCheck}
                    containerStyle={{
                      minWidth: "50vw",
                      minHeight: "50vh",
                      maxHeight: size ? "60vh" : "51vh",
                      transform: facingMode === "environment" ? "rotateY(180deg)" : "",
                    }}
                    videoContainerStyle={{ paddingTop: "80%" }}
                    videoId="QRVIDEO"
                    scanDelay={2000}
                  />

                  <IconButton
                    color="primary"
                    onClick={() => onClick(facingMode === "user" ? "environment" : "user")}
                    aria-label="upload picture"
                    component="label"
                  >
                    <CameraswitchIcon />
                  </IconButton>
                </Card>
              )}
            </div>
          </div>
        </Grid>
      ) : (
        <Grid item lg={6} xs={12}>
          <Card
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "330px",
              textAlign: "center",
            }}
          >
            <CardContent>
              <QrCodeScannerIcon sx={{ fontSize: 250 }} />
              <Typography variant="h5">Scan using Device</Typography>
            </CardContent>
          </Card>
        </Grid>
      )}

      <input
        ref={inputRef}
        type="text"
        style={{ position: "absolute", left: "-9999px" }} // Hide input
        onKeyDown={handleScan}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <Grid item lg={6} xs={12}>
        {data && data.length > 0 && (
          <div
            style={{
              marginTop: "1rem",
              width: "100%",
            }}
          >
            <Card style={{ height: "100%", width: "100%", padding: "0 1rem" }}>
              <InfoCard data={data} printLabel={printLabel} onConfirm={onConfirm} />
            </Card>
          </div>
        )}
        {speaker?.id && (
          <div
            style={{
              marginTop: "1rem",
              width: "100%",
            }}
          >
            <Card style={{ height: "100%", width: "100%", padding: "0 1rem" }}>
              <SpeakerCard speaker={speaker} printLabel={printSpeaker} />
            </Card>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default QrCheck;
